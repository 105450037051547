import React, { useContext } from 'react'
import { pageSectionTypes } from './pageSectionTypes'
import { sectionContentTypes } from './sectionContentTypes'
import { ChildComponentGenerator } from './ChildComponentGenerator'
import { Grid } from '@achieve/sunbeam'
import { FeatureFlagContext } from 'providers/FeatureFlagProvider'
import { DEFAULT_EXPERIMENT, OFF_EXPERIMENT, UNASSIGNED_EXPERIMENT } from 'constants/experiments'

export function PageHandler({ pageContent, isMobileUA = true, isCrawler = false }) {
  const { featureFlags } = useContext(FeatureFlagContext)
  return (pageContent?.fields?.sections || []).map((section) => {
    const experiment = featureFlags[section?.fields?.experiment?.fields?.featureFlagId]
    section.isMobileUA = isMobileUA
    const variation = section?.fields?.experimentVariation
    if (
      !variation ||
      variation === experiment ||
      (variation === DEFAULT_EXPERIMENT &&
        [OFF_EXPERIMENT, UNASSIGNED_EXPERIMENT].includes(experiment))
    ) {
      return (
        <ChildComponentGenerator
          contentTypes={pageSectionTypes}
          uiComponent={section?.fields?.uiComponent}
          isCrawler={isCrawler}
          {...section}
          key={section?.sys?.id}
        />
      )
    }
  })
}

export function SectionHandler({ sectionContents }) {
  return (sectionContents || []).map((sectionContent) => {
    return (
      <ChildComponentGenerator
        contentTypes={sectionContentTypes}
        uiComponent={sectionContent.fields.uiComponent}
        {...sectionContent}
        key={sectionContent?.sys?.id}
      />
    )
  })
}

export function GridHandler({ gridContents, columns, ...gridProps }) {
  return (gridContents || []).map((gridContent, index) => {
    return (
      <Grid item xs={12 / columns} key={`${gridContent?.sys?.id}-${index}`} {...gridProps}>
        <ChildComponentGenerator
          contentTypes={sectionContentTypes}
          uiComponent={gridContent.fields.uiComponent}
          {...gridContent}
        />
      </Grid>
    )
  })
}
