import dynamic from 'next/dynamic'
import { VideoDialog } from 'components/VideoDialog'
import { ValueProps } from 'pageSections/ValueProps'
import { PoliciesHero, PoliciesBody } from 'pageSections/Policies'
import { AboutUsHero } from 'pageSections/About/AboutUsHero'
import { HomeHero } from 'pageSections/Home/HomeHero'
import { HomeHeroV2 } from 'pageSections/Home/HomeHeroV2'
import { ProductHero } from 'pageSections/ProductHero/ProductHero'
import { HeroSection } from 'pageSections/HeroSection'
import { EmployeeStoriesHero } from 'pageSections/EmployeeStories/EmployeeStoriesHero'
import { MoloHero } from 'pageSections/MoloHero'
import { SectionFullBackground } from 'pageSections/Employee/SectionFullBackground'

const TrustMarkerGrid = dynamic(() => import('pageSections/TrustMarkerGrid/TrustMarkerGrid'))

const AboutCloserStatement = dynamic(() =>
  import('pageSections/About/AboutCloserStatement/AboutCloserStatement')
)
const AboutEmployeeResourceGroups = dynamic(() =>
  import('pageSections/About/AboutEmployeeResourceGroups/AboutEmployeeResourceGroups')
)
const AboutUsLeadership = dynamic(() =>
  import('pageSections/About/AboutUsLeadership/AboutUsLeadership')
)
const CardGrid = dynamic(() => import('pageSections/CardGrid/CardGrid'))
const CloserStatement = dynamic(() => import('pageSections/CloserStatement/CloserStatement'))
const FeaturedStory = dynamic(() =>
  import('pageSections/MemberStories/FeaturedStory/FeaturedStory')
)
const ImageGridSection = dynamic(() =>
  import('pageSections/MemberStories/ImageGridSection/ImageGridSection')
)
const MemberStoriesCarousel = dynamic(() =>
  import('pageSections/MemberStories/MemberStoriesCarousel/MemberStoriesCarousel')
)
const MemberStoriesCloserStatement = dynamic(() =>
  import('pageSections/MemberStories/MemberStoriesCloserStatement/MemberStoriesCloserStatement')
)
const ARCloser = dynamic(() => import('pageSections/ARCloser/ARCloser'))
const Faqs = dynamic(() => import('pageSections/Faqs/Faqs'))
const HomeMadLib = dynamic(() => import('pageSections/Home/HomeMadLib/HomeMadLib'))
const ProblemStatement = dynamic(() => import('pageSections/ProblemStatement/ProblemStatement'))
const ByTheNumbers = dynamic(() => import('pageSections/ByTheNumbers/ByTheNumbers'))
const ProductCards = dynamic(() => import('pageSections/ProductCards/ProductCards'))
const PhoneAndNumbers = dynamic(() => import('pageSections/PhoneAndNumbers/PhoneAndNumbers'))
const AboutUsVideo = dynamic(() => import('pageSections/About/AboutUsVideo/AboutUsVideo'))
const MoreResourcesCarousel = dynamic(() =>
  import('pageSections/MoreResourcesCarousel/MoreResourcesCarousel')
)
const TestimonialCarousel = dynamic(() =>
  import('pageSections/TestimonialCarousel/TestimonialCarousel')
)
const TestimonialCarouselV2 = dynamic(() =>
  import('pageSections/TestimonialCarouselV2/TestimonialCarouselV2')
)
const StoryBlocks = dynamic(() => import('pageSections/StoryBlocks/StoryBlocks'))
const StoryBlocksAuthor = dynamic(() => import('pageSections/StoryBlocksAuthor/StoryBlocksAuthor'))
const AddressSection = dynamic(() => import('pageSections/AddressSection/AddressSection'))
const PageTitle = dynamic(() => import('pageSections/PageTitle/PageTitle'))
const StickySubNavWrapper = dynamic(() =>
  import('pageSections/StickySubNavWrapper/StickySubNavWrapper')
)
const PressKitDownload = dynamic(() =>
  import('pageSections/PressKitSection/PressKitDownload/PressKitDownload')
)
const PaginatedStoryGrid = dynamic(() =>
  import('pageSections/PaginatedStoryGrid/PaginatedStoryGrid')
)
const PaginatedArticlesGrid = dynamic(() =>
  import('pageSections/PaginatedArticlesGrid/PaginatedArticlesGrid')
)
const SmsDisclosures = dynamic(() => import('pageSections/SmsDisclosures/SmsDisclosures'))
const MoloSection = dynamic(() => import('pageSections/MoloSection/MoloSection'))
const CloserStatementApp = dynamic(() =>
  import('pageSections/CloserStatementApp/CloserStatementApp')
)
const TrustMarkersSection = dynamic(() =>
  import('pageSections/TrustMarkersSection/TrustMarkersSection')
)
const EmployeeStoriesGrid = dynamic(() =>
  import('pageSections/EmployeeStoriesGrid/EmployeeStoriesGrid')
)

const EmployeeStoriesPolaroidCollage = dynamic(() =>
  import(
    'pageSections/EmployeeStories/EmployeeStoriesPolaroidCollage/EmployeeStoriesPolaroidCollage'
  )
)
const TestimonialFullBackgroundCarousel = dynamic(() =>
  import('pageSections/TestimonialFullBackgroundCarousel/TestimonialFullBackgroundCarousel')
)
const PartnersForm = dynamic(() => import('pageSections/PartnersForm/PartnersForm'))
const EmailForm = dynamic(() => import('pageSections/EmailForm/EmailForm'))
const FeaturedPressCarousel = dynamic(() =>
  import('pageSections/Press/FeaturedPressCarousel/FeaturedPressCarousel')
)
const MultiSlider = dynamic(() => import('pageSections/MultiSlider/MultiSlider'))
const DTIProductCards = dynamic(() => import('pageSections/DTIProductCards/DTIProductCards'))
const DTICalculator = dynamic(() => import('pageSections/DTICalculator/DTICalculator'))
const DebtAssessmentSection = dynamic(() =>
  import('pageSections/DebtAssessmentSection/DebtAssessmentSection')
)
const ArticlesCarousel = dynamic(() => import('pageSections/ArticlesCarousel/ArticlesCarousel'))
const SkinnyForm = dynamic(() => import('pageSections/SkinnyForm/SkinnyForm'))
const ValuePropsFull = dynamic(() => import('pageSections/ValuePropsFull/ValuePropsFull'))
const TestimonialCarouselFull = dynamic(() =>
  import('pageSections/TestimonialCarouselFull/TestimonialCarouselFull')
)
const PromoCards = dynamic(() => import('pageSections/PromoCards/PromoCards'))
const ProductDetailsTabs = dynamic(() =>
  import('pageSections/ProductDetailsTabs/ProductDetailsTabs')
)

const DebtConsolidationSolutions = dynamic(() =>
  import('pageSections/DebtConsolidationSolutions/DebtConsolidationSolutions')
)

const StatBarGrid = dynamic(() => import('pageSections/StatBarGrid/StatBarGrid'))
const CloserStatementSection = dynamic(() =>
  import('pageSections/CloserStatementSection/CloserStatementSection')
)
const SubnavSection = dynamic(() => import('components/SubnavSection/SubnavSection'))

const TrustpilotCarouselSection = dynamic(() =>
  import('pageSections/TrustpilotCarouselSection/TrustpilotCarouselSection')
)

const PaginatedAuthorsList = dynamic(() =>
  import('pageSections/PaginatedAuthorsList/PaginatedAuthorsList')
)

// values in the componentMap need to match dropdown options in Contentful
export const pageSectionTypes = {
  AboutCloserStatement,
  AboutEmployeeResourceGroups,
  AboutUsHero,
  AboutUsLeadership,
  AboutUsVideo,
  AddressSection,
  ByTheNumbers,
  CardGrid,
  CloserStatement,
  EmployeeStoriesPolaroidCollage,
  EmployeeStoriesHero,
  Faqs,
  FeaturedPressCarousel,
  FeaturedStory,
  HeroSection,
  HomeHero,
  HomeHeroV2,
  HomeMadLib,
  ImageGridSection,
  MemberStoriesCarousel,
  MemberStoriesCloserStatement,
  MoloHero,
  MoreResourcesCarousel,
  PageTitle,
  PaginatedArticlesGrid,
  PaginatedStoryGrid,
  PoliciesBody,
  PoliciesHero,
  PressKitDownload,
  ProblemStatement,
  ProductCards,
  ProductHero,
  StickySubNavWrapper,
  StoryBlocks,
  TestimonialCarousel,
  ValueProps,
  VideoDialog,
  SmsDisclosures,
  EmailForm,
  PhoneAndNumbers,
  MoloSection,
  MultiSlider,
  CloserStatementApp,
  TrustMarkersSection,
  EmployeeStoriesGrid,
  TestimonialFullBackgroundCarousel,
  SectionFullBackground,
  StoryBlocksAuthor,
  PartnersForm,
  DTIProductCards,
  DTICalculator,
  DebtAssessmentSection,
  ArticlesCarousel,
  SkinnyForm,
  ValuePropsFull,
  TestimonialCarouselFull,
  TestimonialCarouselV2,
  PromoCards,
  TrustMarkerGrid,
  ProductDetailsTabs,
  DebtConsolidationSolutions,
  StatBarGrid,
  ARCloser,
  CloserStatementSection,
  SubnavSection,
  TrustpilotCarouselSection,
  PaginatedAuthorsList,
}
