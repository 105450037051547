import { SectionProvider } from 'providers/SectionProvider'
import React from 'react'

export function ChildComponentGenerator({
  contentTypes,
  uiComponent,
  isCrawler = false,
  ...props
}) {
  if (!uiComponent || !contentTypes) {
    return null
  }
  const COMPONENT = contentTypes[uiComponent]

  if (!COMPONENT) {
    return null
  }

  return (
    <SectionProvider
      variation={
        { ...props?.fields?.experiment, variant: props?.fields?.experimentVariation } || false
      }
    >
      {React.createElement(COMPONENT, {
        content: { ...props, isCrawler },
      })}
    </SectionProvider>
  )
}
