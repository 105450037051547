import { BREAKPOINTS, useViewportSmallerThan } from 'utils/mui'

/**
 * Returns the appropriate media content based on the viewport size.
 *
 * @param {Object} options - The options for the media content.
 * @param {Object} options.mediaContent - The default media content.
 * @param {Object} options.middleMediaContent - The media content for middle viewport size.
 * @param {Object} options.mobileMediaContent - The media content for mobile viewport size.
 * @returns {Object} The final background media content object.
 */
function useResponsiveMediaContent({ mediaContent, middleMediaContent, mobileMediaContent }) {
  const isXS = useViewportSmallerThan(BREAKPOINTS.md)
  const isMD = useViewportSmallerThan(BREAKPOINTS.lg)

  let finalBackground = {}

  finalBackground = mediaContent
  if (isXS && mobileMediaContent) {
    finalBackground = mobileMediaContent
  }
  if (isMD && !isXS && middleMediaContent) {
    finalBackground = middleMediaContent
  }

  return finalBackground
}

export default useResponsiveMediaContent
