import React from 'react'
import { Typography } from 'components/Contentful'
import { Eyebrow } from 'components/Eyebrow'
import classNames from 'classnames'
import styles from './TitleDivider.module.scss'

/**
 * @param {Object} props
 * @param {boolean|false} props.eyebrowBlack Use to set eyebrow 'black' variant
 */
function TitleDivider({
  eyebrow,
  eyebrowBlack = false,
  eyebrowClassName,
  title,
  subtitle,
  className,
  variants,
  titleTag = 'h2',
  subtitleTag = 'h3',
  disableTitleTag = false,
  disableSubtitleTag = false,
}) {
  return (
    <div className={`${styles['title-divider']} ${className ? className : ''}`}>
      {eyebrow && (
        <Eyebrow
          content={eyebrow?.textContent}
          className={eyebrowClassName}
          data-testid="title-divider-eyebrow"
          black={eyebrowBlack}
        />
      )}
      {title && (
        // The initial display for the title will be an h2 with Ascend type style displayM20.
        // Responsive size changes are handled in global type style `section-title`
        <Typography
          variant="displayM20"
          data-testid="title-divider-title"
          className={classNames('section-title', styles['title'])}
          content={title?.textContent}
          {...(!disableTitleTag && { component: titleTag })}
        />
      )}
      {subtitle && (
        <Typography
          data-testid="title-divider-subtitle"
          className={styles['subtitle']}
          content={subtitle.textContent}
          variant={variants?.subtitle}
          fontWeight={variants?.subtitleFontWeight}
          {...(!disableSubtitleTag && { component: subtitleTag })}
        />
      )}
    </div>
  )
}

export { TitleDivider }
