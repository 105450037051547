import { useContext } from 'react'
import { Button as SunbeamButton } from '@achieve/sunbeam'
import { AnalyticsContext } from 'providers/AnalyticsProvider'
import { handleTrackAndReactEvent } from 'utils/analytics'
import { updateTrackWithListedEvent } from 'utils/analytics/trackingLibrary/updateTrackEventWithListedEvent'

import { useEventScope } from 'hooks/useEventScope'

function Button(props) {
  let track = props.track
  const { dispatch } = useContext(AnalyticsContext)
  const specificity = useEventScope()

  if (track) {
    track = updateTrackWithListedEvent(track, specificity)
  }

  const localClickHandler = (event, track, onClick) => {
    dispatch({
      type: 'ADD_EVENT_TO_QUEUE',
      payload: {
        event_type: track?.event_type,
        ...handleTrackAndReactEvent(event, track, onClick),
      },
    })
  }

  return props.defaultbutton ? (
    <button
      {...props}
      onClick={(event) => {
        localClickHandler(event, track, props.onClick)
      }}
    >
      {props.children}
    </button>
  ) : (
    <SunbeamButton
      {...props}
      onClick={(event) => {
        localClickHandler(event, track, props.onClick)
      }}
    >
      {props.children}
    </SunbeamButton>
  )
}

export { Button }
