import Section from 'components/Section'
import styles from './ProductHero.module.scss'
import { HeroForm } from 'components/HeroForm'
import { useMemoizedContentGetter } from 'utils/contentful'
import { richTextSchema, imageFieldSchema } from 'constants/validation-types'
import { object, string, array, boolean, number } from 'yup'

const contentSchema = object({
  title: object({
    textContent: richTextSchema,
  }).required(),
  subtitle: object({
    textContent: richTextSchema,
  }).required(),
  breadcrumb: object({
    textContent: richTextSchema,
  }).required(),
  image: object({
    mediaContent: imageFieldSchema,
    mobileMediaContent: imageFieldSchema,
  }).required(),
  sliderStatsTitle: object({
    textContent: richTextSchema,
  }).required(),
  sliderQuestion: object({
    textContent: richTextSchema,
  }).required(),
  sliderProps: object({
    jsonContent: object({
      ctaText: string(),
      ctaLinkBase: string(),
      stats: array().of(object({ figure: string(), group: string() })),
      sliderQueryParam: string(),
      slider: object({
        min: number(),
        max: number(),
        step: number(),
        maxPlus: boolean(),
      }),
    }),
  }).required(),
})

function ProductHeroModule({
  title,
  subtitle,
  breadcrumb,
  image,
  sliderStatsTitle,
  sliderQuestion,
  sliderProps,
  isMobileUA = true,
  hasPhone = false,
  phoneWrapper,
}) {
  return (
    <Section
      className={styles['product-hero-section']}
      childContainerClassName={styles['product-hero-container']}
    >
      <HeroForm
        title={title?.textContent}
        subtitle={subtitle?.textContent}
        breadcrumb={breadcrumb?.textContent}
        image={image?.mediaContent}
        mobileImage={image?.mobileMediaContent}
        alt={image?.mediaAltText}
        sliderStatsTitle={sliderStatsTitle?.textContent}
        sliderQuestion={sliderQuestion?.textContent}
        sliderProps={sliderProps?.jsonContent}
        isMobileUA={isMobileUA}
        hasPhone={hasPhone}
        phoneWrapper={phoneWrapper}
      />
    </Section>
  )
}

function ProductHero({ content }) {
  const memoizedContent = useMemoizedContentGetter(content, [
    'title',
    'subtitle',
    'breadcrumb',
    'image',
    'sliderStatsTitle',
    'sliderProps',
    'sliderQuestion',
  ])

  if (!contentSchema.isValidSync(memoizedContent)) {
    console.warn('ProductHero - INVALID CONTENT RESPONSE', JSON.stringify(memoizedContent))
    return null
  }

  const hasPhone = content?.fields?.additionalConfiguration?.fields?.jsonContent?.phone
  const phoneWrapper = content?.fields?.additionalConfiguration?.fields?.jsonContent?.phoneWrapper

  const { title, subtitle, breadcrumb, image, sliderStatsTitle, sliderProps, sliderQuestion } =
    memoizedContent

  return (
    <ProductHeroModule
      title={title}
      subtitle={subtitle}
      breadcrumb={breadcrumb}
      image={image}
      sliderStatsTitle={sliderStatsTitle}
      sliderQuestion={sliderQuestion}
      sliderProps={sliderProps}
      isMobileUA={content?.isMobileUA}
      hasPhone={hasPhone}
      phoneWrapper={phoneWrapper}
    />
  )
}
export { ProductHero, ProductHeroModule }
export default ProductHero
