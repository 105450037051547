import { useMemoizedContentGetter } from 'utils/contentful'
import Section from 'components/Section'
import styles from './SubnavSection.module.scss'
import { useRouter } from 'next/router'
import { Tab, Tabs } from '@achieve/sunbeam'
import { useEffect, useRef, useState } from 'react'
import { BREAKPOINTS, useViewportSmallerThan } from 'utils/mui'
import { AchieveLink } from 'components/AchieveLink'
import useIsSsr from 'hooks/useIsSsr'
import useLayoutMenuCondensed from 'hooks/useLayoutMenuCondensed'

function SubnavSectionModule({ grid, config }) {
  const isSsr = useIsSsr()
  const scrollingTabs = useViewportSmallerThan(BREAKPOINTS[config?.scrollingOn ?? 'lg'])
  const router = useRouter()
  const trackRefLink = useRef()
  const [linkPage, setLinkPAge] = useState(null)
  const [value, setValue] = useState(null)
  const [navFixed, setNavFixed] = useState(true)
  const [menuCondensed] = useLayoutMenuCondensed()
  const [tabsOffset, setTabsOffset] = useState(54)

  const handleTabChange = (event, newValue) => {
    setValue(newValue)
    const idx = grid?.gridItems
      .map((item) => item?.fields?.linkText?.content?.[0]?.content?.[0]?.value)
      .indexOf(newValue)
    if (idx >= 0) setLinkPAge(grid?.gridItems[idx])
  }

  useEffect(() => {
    if (linkPage) trackRefLink?.current?.click()
  }, [linkPage])

  useEffect(() => {
    if (grid) {
      const idx = grid?.gridItems.map((item) => item?.fields?.linkHref).indexOf(router?.pathname)
      if (idx >= 0)
        setValue(grid?.gridItems[idx]?.fields?.linkText?.content?.[0]?.content?.[0]?.value)
    }
  }, [grid, router])

  useEffect(() => {
    if (isSsr) {
      // no window to measure during SSR
      return
    }
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSsr])

  const scrollHandler = () => {
    const lineTop = window.pageYOffset
    const element = document.getElementById('main-layout')?.children?.[1]
    setNavFixed(lineTop + 100 <= element?.offsetTop + element?.offsetHeight)
  }

  useEffect(() => {
    // const DEFAULT_MOBILE_TOP_POSITION = menuCondensed ? 54: 54
    // const DEFAULT_DESKTOP_TOP_POSITION = menuCondensed ? 64: 64
    if (scrollingTabs) {
      setTabsOffset(menuCondensed ? 54 : 72)
    } else {
      setTabsOffset(menuCondensed ? 64 : 64)
    }
  }, [scrollingTabs, menuCondensed])

  return (
    <Section
      className={styles['subnav-section']}
      childContainerClassName={styles['subnav-section-container']}
      data-testid="subnav-section"
      data-fixed={navFixed}
      contain={!scrollingTabs}
      style={{ top: navFixed ? tabsOffset : 0 }}
    >
      <Tabs
        value={value}
        onChange={handleTabChange}
        variant={scrollingTabs ? 'scrollable' : 'standard'}
        scrollButtons="auto"
        allowScrollButtonsMobile={true}
        aria-label="sticky scrollable tabs component"
        indicatorColor={'none'}
        centered
      >
        {grid?.gridItems.map((item, i) => {
          const tabLabel = item?.fields?.linkText?.content?.[0]?.content?.[0]?.value
          return <Tab key={`tab-${i}`} label={tabLabel} value={tabLabel} data-testid={`tab-${i}`} />
        })}
      </Tabs>
      <AchieveLink
        href={linkPage?.fields?.linkHref}
        className={styles['subnav-link']}
        data-testid="subnav-link"
        track={{
          list_name: 'Subnav Link',
          click_id: linkPage?.fields?.linkText?.content[0]?.content[0]?.value,
          click_text: linkPage?.fields?.linkText?.content[0]?.content[0]?.value,
          click_url: linkPage?.fields?.linkHref,
        }}
      >
        <div ref={trackRefLink}></div>
      </AchieveLink>
    </Section>
  )
}

function SubnavSection({ content }) {
  const { grid, config } = useMemoizedContentGetter(content, ['grid', 'config'])

  return <SubnavSectionModule grid={grid} config={config?.jsonContent} />
}
export { SubnavSection, SubnavSectionModule }
export default SubnavSection
